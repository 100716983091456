import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { ImmutableArray, ImmutableObject } from 'seamless-immutable';

import { TabContext, TabPanel } from '@mui/lab';
import { CircularProgress, Tab, Tabs } from '@mui/material';

import { ChangesLogItem } from 'types/Common/History';
import { Job } from 'types/Job';

import { JobType } from 'Constants/job';
import { JobsAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import AppPaperModal from 'components/AppPaperModal';
import ChangesHistory from 'components/ChangesHistory';

import styles from './NotesModal.module.scss';

type Props = {
  jobId: number;
  open: boolean;
  onClose: () => void;
  title?: string;
};

type TabType = 'comments' | 'history';

const tabs = {
  comments: 'comments',
  history: 'history',
} as { [key: string]: TabType };

const NoDataContainer = ({ children }) => {
  return <div className={styles.noDataContainer}>{children}</div>;
};

export const NotesModal: FC<Props> = ({ jobId, open, onClose, title = 'Activity Log and Comments' }) => {
  const [tab, setTab] = useState<TabType>(tabs.comments);
  const [job, setJob] = useState<Job>(null);
  const { inProcess, promiseWrapper } = useProcessing();

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: TabType) => {
    setTab(newValue);
  };

  const skipFilter = useCallback((log: ImmutableObject<ChangesLogItem>) => {
    const skipBodyValues = ['Upload job images'];
    return skipBodyValues.some((value) => log.body.includes(value));
  }, []);

  const logBodyContainsExceptions = useCallback((body: string) => {
    const exceptions = ['replaces', 'was replaced by'];
    if (!body) {
      return true;
    }
    return exceptions.some((exception) => body.includes(exception));
  }, []);

  const logIsProbablyComment = useCallback((log: ImmutableObject<ChangesLogItem>) => {
    return (
      skipFilter(log) ||
      (!log.fields?.length &&
        log.source !== 'Automatic' &&
        log.updaterName !== 'System' &&
        !logBodyContainsExceptions(log.body))
    );
  }, []);

  const dispatchCommentsOnly = useMemo(() => {
    return job?.changesLog.filter(logIsProbablyComment) || [];
  }, [job?.changesLog]) as ImmutableArray<ChangesLogItem>;

  useEffect(() => {
    if (open && jobId) {
      promiseWrapper(JobsAPI.getJob({ jobId })).then(setJob).catch(showErrorMessage);
    }
  }, [open, jobId, promiseWrapper]);

  return (
    <AppPaperModal
      title={title}
      open={open}
      onClose={onClose}
      noActions
      contentStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      containerStyle={{ width: '100%', maxWidth: '800px' }}
    >
      <TabContext value={tab}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Dispatch Comments" value="comments" />
          <Tab label="Job History" value="history" />
        </Tabs>
        {inProcess ? (
          <NoDataContainer>
            <CircularProgress />
          </NoDataContainer>
        ) : (
          <div className={styles.tabContent}>
            <TabPanel value={tabs.comments} className={classNames([styles.tabPanel, styles.dispatcherCommentsList])}>
              {!dispatchCommentsOnly.length && <NoDataContainer>No comments yet</NoDataContainer>}
              {dispatchCommentsOnly.map((log) => (
                <div className={styles.dispatcherComment}>
                  <span className={styles.commentDate}>{moment(log.created_at).format('MM/DD h:mm A')}</span>
                  <span className={styles.commentBody}>{log.body}</span>
                  <span className={styles.commentAuthor}>{log.added_by_user_name}</span>
                </div>
              ))}
            </TabPanel>
            <TabPanel value={tabs.history} className={classNames([styles.tabPanel, styles.history])}>
              {!job?.changesLog?.length && <NoDataContainer>No history yet</NoDataContainer>}
              {job?.changesLog?.length && <ChangesHistory changesLog={job.changesLog} jobType={JobType[job.jobType]} />}
            </TabPanel>
          </div>
        )}
      </TabContext>
    </AppPaperModal>
  );
};
